<template>
  <div class="booking-cancel-reject-reason">
    <div class="card">
      <modal-body class="p-3">
        <div
          v-if="isLoading"
          class="
            d-flex
            justify-content-center
            align-items-center
            spinner-container
          "
        >
          <spinner></spinner>
        </div>
        <div v-else>
          <h3 class="mb-4">{{ type }} Reason</h3>
          <display-data label="Reason" :content="reason.message"></display-data>
        </div>
      </modal-body>

      <modal-footer class="row justify-content-end p-2">
        <button type="button" class="btn main" @click="$emit('cancel')">
          OK
        </button>
      </modal-footer>
    </div>
  </div>
</template>

<script>
import { projectBooking as bookingAPI } from "@/api";
import { projectBookingStatus } from "@/enums";

export default {
  components: {
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner"),
    DisplayData: () => import("@/components/GlobalComponents/DisplayData")
  },
  mixins: [],
  props: {
    type: {
      type: String,
      required: true
    },
    bookingId: {
      type: [Number, String],
      required: true
    }
  },
  data: function () {
    return {
      isLoading: false,

      reason: {
        description: ""
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.getReason();
  },
  methods: {
    async getReason() {
      try {
        this.isLoading = true;

        switch (this.type) {
          case projectBookingStatus.REJECTED:
            this.reason = await bookingAPI.getRejectedReason(this.bookingId);
            break;
          case projectBookingStatus.CANCELLED:
            this.reason = await bookingAPI.getCancelledReason(this.bookingId);
            break;

          default:
            break;
        }

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: `Failed to get booking ${this.type} reason. Please try again later.`
        });
      }
    }
  }
};
</script>

<style lang="scss">
.booking-cancel-reject-reason {
  min-width: 500px;

  .spinner-container {
    min-height: 200px;
  }
}
</style>
